import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import LineVector from "../LineVector"
import ModalBootstrap from "../ModalBootstrap"
import Title from "../Title"
import styled from "styled-components"
import ChevronLeftIcon from "../Icons/ChevronLeftIcon"
import ChevronRightIcon from "../Icons/ChevronRightIcon"
import Carousel from "../Carousel"

import { BREAKPOINTS } from "../../utils/styled_config"
import Element from "../UI/Element"
import { useServicesData } from "../../hooks/use-services-data"
import { useProjectsData } from "../../hooks/use-projects-data"

const ServicesModal = ({ show, handleClose, serviceId, name }) => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [slider1, setSlider1] = useState(null)
  const [slider2, setSlider2] = useState(null)

  useEffect(() => {
    setNav1(slider1)
    setNav2(slider2)
  })

  const PrevArrow = ({ onClick, className }) => {
    return (
      <ArrowButton onClick={onClick} className={className}>
        <ChevronLeftIcon />
      </ArrowButton>
    )
  }

  const NextArrow = ({ onClick, className }) => {
    return (
      <ArrowButton onClick={onClick} className={className}>
        <ChevronRightIcon />
      </ArrowButton>
    )
  }
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    asNavFor: nav2,
    ref: slider => setSlider1(slider),
  }
  const settingsDetails = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: nav1,
    ref: slider => setSlider2(slider),
  }

  const projects = useProjectsData()

  const services = useServicesData()

  const projectList = projects.filter(
    item => item.service_ids.indexOf(serviceId) !== -1
  )

  return (
    <ModalBootstrap show={show} handleClose={handleClose}>
      <Element mb={6}>
        <Slider>
          <Carousel settings={settings}>
            {projectList.map(p => (
              <div>
                <Element mb={6}>
                  <GatsbyImage
                    image={getImage(p.slider_imgs[0])}
                    alt="slider"
                  />
                </Element>
              </div>
            ))}
          </Carousel>
        </Slider>
        <Carousel settings={settingsDetails}>
          {projectList.map(p => (
            <div>
              <Service>
                {services.find(i => i.service_id === serviceId).name}
              </Service>
              <Title size="h2">{p.name}</Title>
              <Element>
                <LineVector width={200} height={12} />
              </Element>

              <Element>{p.location}</Element>
            </div>
          ))}
        </Carousel>
      </Element>
    </ModalBootstrap>
  )
}

const Slider = styled.div`
  margin-bottom: 28px;
  .slick-dots {
    position: static;
  }
`

const Service = styled.div`
  text-transform: uppercase;
  margin-bottom: 16px;
  letter-spacing: 0.08em;
`

const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  height: 40px;
  width: 40px;
  z-index: 2;

  svg {
    font-size: 20px;
  }

  &.slick-prev {
    left: 0;
  }

  &.slick-next {
    right: 0;
  }

  &.slick-disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  path {
    fill: #fff !important;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    height: 56px;
    width: 56px;

    svg {
      font-size: 28px;
    }
  }
`

export default ServicesModal

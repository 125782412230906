import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Element from "../UI/Element"

import { BREAKPOINTS } from "../../utils/styled_config"
import Row from "../Row"
import Col from "../Col"
import Title from "../Title"
import Paragraph from "../Paragraph"
import ViewPhotos from "../ViewPhotos"
import { useProjectsData } from "../../hooks/use-projects-data"

const ServiceRow = ({
  name,
  imgSrc,
  items,
  description,
  maxWidth,
  index,
  onClick,
  serviceId,
}) => {
  const projects = useProjectsData()

  const projectList = projects.filter(
    item => item.service_ids.indexOf(serviceId) !== -1
  )

  return (
    <ServiceRowStyled>
      <Col lg="auto">
        <ImageHolder>
          <Element maxWidth={{ _: "100%", md: maxWidth }}>
            <GatsbyImage image={getImage(imgSrc)} alt={name} />
          </Element>
          {projectList && projectList.length > 0 && (
            <Element textAlign={{ lg: index % 2 === 0 ? "left" : "right" }}>
              <ViewPhotos onClick={onClick} />
            </Element>
          )}
        </ImageHolder>
      </Col>
      <Col md>
        <Title size={3} lg={2}>
          {name}
        </Title>
        <Paragraph>{description}</Paragraph>
        {items && (
          <ul>
            {items.map(item => (
              <li key={item.id}>{item.name}</li>
            ))}
          </ul>
        )}
      </Col>
    </ServiceRowStyled>
  )
}

const ServiceRowStyled = styled(Row)`
  justify-content: space-between;
  & + & {
    margin-top: 40px;
  }

  ${Paragraph} {
    margin-bottom: 1rem;
  }

  ${Title} {
    margin-bottom: 1rem;
  }

  ul {
    line-height: 1.8;
    margin: 0;
    margin-left: 16px;
    list-style-type: disc;

    li {
      padding-left: 4px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    .gatsby-image-wrapper {
      margin-top: 0;
    }

    &:nth-child(even) {
      & > div:first-child {
        order: 999999;
      }
    }
  }
`

const ImageHolder = styled.div`
  position: relative;
  margin-bottom: 1rem;

  button {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
`

export default ServiceRow

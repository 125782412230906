import React from "react"
import Hero from "../Hero"
import HeroImg from "../../images/hero_services.jpg"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import Title from "../Title"
import styled from "styled-components"
import LinedText from "../LinedText"

const ServicesHero = () => {
  return (
    <Hero img={HeroImg} page="inner">
      <Container>
        <Row>
          <Col col={10} lg={5} xl={6}>
            <TitleStyled size="d4" lg="d1">
              Leading The Success 
              <br />
              <LinedText title=" of Every Project" height="13px" />
            </TitleStyled>
          </Col>
        </Row>
      </Container>
    </Hero>
  )
}

const TitleStyled = styled(Title)`
  line-height: 1;
`

export default ServicesHero

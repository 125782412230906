import React from "react"
import styled from "styled-components"
import Hero from "./Hero"
import HeroImg from "../images/hero_contact.jpg"
import { theme } from "../utils/styled_config"
import Container from "./Container"
import Row from "./Row"
import Col from "./Col"
import Title from "./Title"
import LinedText from "./LinedText"
import ArrowCta from "./ArrowCta"

const ContactLead = () => {
  return (
    <ContactLeadStyled>
      <Hero img={HeroImg} page="reverse" target="lead">
        <Container>
          <Row justifyLg="flex-end">
            <Col col={10} lg={5} xl={6}>
              <Title size="1" lg="d2" mb={6} mbLg={10}>
                Find out how we can build your{" "}
                <LinedText title="business" height="9px" close={true} />.
              </Title>
              <ArrowCta
                to="/contact-us"
                title="Talk To Us"
                arrow="circle"
                align="center"
              />
            </Col>
          </Row>
        </Container>
      </Hero>
    </ContactLeadStyled>
  )
}

const ContactLeadStyled = styled.div`
  border-top: 1px solid ${theme.sepator.bg};
`

export default ContactLead

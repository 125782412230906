import { graphql, useStaticQuery } from "gatsby"

export const useProjectsData = () => {
  const data = useStaticQuery(
    graphql`
      query ProjectsQuery {
        allProjectsJson {
          nodes {
            id
            project_id
            name
            featured
            location
            soon
            category_ids
            service_ids
            img_thumbnail {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
              }
            }
            slider_imgs {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
              }
            }
            excerpt
            description
          }
        }
      }
    `
  )
  return data.allProjectsJson.nodes
}

import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Wrapper, Grid, Tile } from "../UI/Grid"
import Section from "../Section"
import Title from "../Title"
import ServiceRow from "./service_row"
import styled from "styled-components"
import { BREAKPOINTS } from "../../utils/styled_config"
import ServicesModal from "./ServicesModal"

const ServicesSection = () => {
  const [show, setShow] = useState(false)
  const [selectedService, setSelectedService] = useState()

  const handleClose = () => {
    setShow(false)
  }

  const handleServiceClick = id => {
    setShow(true)
    setSelectedService(id)
  }

  const services = useStaticQuery(graphql`
    query ServicePageQuery {
      allServicesJson {
        edges {
          node {
            id
            service_id
            name
            description
            items {
              id
              name
            }
            img_src {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP])
              }
            }
            max_width
          }
        }
      }
    }
  `)

  return (
    <Section>
      <Wrapper>
        <Grid justifyContent="center">
          <Tile lg={9} textAlign="center">
            <Title size={3}>
              From feasibility, acquisition, masterplanning, project development to operations... we guide you through all the way.  Over the years, we have earned our clients’ trust resulting to multiple projects as we understand the complexity of land ownership, construction, operations and even family generational relationships.
            </Title>
          </Tile>
        </Grid>
      </Wrapper>
      <ContainerService className="ServiceRow--custom">
        {services &&
          services.allServicesJson.edges.map((s, idx) => (
            <ServiceRow
              serviceId={s.node.service_id}
              name={s.node.name}
              imgSrc={s.node.img_src}
              description={s.node.description}
              items={s.node.items}
              key={s.node.id}
              maxWidth={s.node.max_width}
              index={idx}
              onClick={() => handleServiceClick(s.node.service_id)}
            />
          ))}
      </ContainerService>

      <ServicesModal
        show={show}
        handleClose={handleClose}
        serviceId={selectedService}
      ></ServicesModal>
    </Section>
  )
}

const ContainerService = styled(Wrapper)`
  margin-top: 40px;

  &.ServiceRow--custom {
    display: flex;
    flex-direction: column;
    div {
      &:nth-of-type(7) {
        order: 4;
        flex-direction: row-reverse;
      }
      &:nth-of-type(8) {
        order: 3;
        flex-direction: row-reverse;
      }
    }
  }
  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    margin-top: 80px;
  }
`

export default ServicesSection

import { graphql, useStaticQuery } from "gatsby"

export const useServicesData = () => {
  const data = useStaticQuery(
    graphql`
      query ServicesQuery {
        allServicesJson {
          nodes {
            id
            service_id
            name
          }
        }
      }
    `
  )
  return data.allServicesJson.nodes
}

import React from "react"
import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
import ServicesHero from "../components/services/ServicesHero"
import ServicesSection from "../components/services/services"
import ContactLead from "../components/ContactLead"

const Services = () => {
  return (
    <>
      <Seo title="Services" />
      <BlurredSection>
        <ServicesHero />
        <ServicesSection />
        <ContactLead />
      </BlurredSection>
    </>
  )
}

export default Services

import React from "react"
import styled from "styled-components"
import theme from "../utils/theme"
import Element from "./UI/Element"
import EyeIcon from "./Icons/EyeIcon"

const ViewPhotos = ({ onClick, title }) => {
  return (
    <Button onClick={onClick}>
      <Element mr={2} fontSize="h4">
        <EyeIcon />
      </Element>
      {title ? title : 'View Photos'}
    </Button>
  )
}

const Button = styled.button`
  border: 0;
  cursor: pointer;
  border-radius: 1000px;
  background: ${theme.colors.brand};
  color: white;
  min-height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  font-weight: ${theme.fontWeights.medium};
  font-size: 12px;
`

export default ViewPhotos
